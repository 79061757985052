import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { navigate } from "gatsby"

import Layout from "../components/layouts"
import SEO from "../components/seo"
import Button from "../components/ui/Button"
import Typography from "../components/ui/Typography"

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 10rem;
  height: calc(100vh - ${(props) => props.theme.navbar.height});
  h1 {
    ${Typography.Large};
    margin-bottom: 0;
    font-weight: normal;
  }
`

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="Not found" />
      <Container>
        <div>
          <h1>We apologize</h1>
          <p>This page is not available.</p>
          <Button onClick={() => navigate("/")}>Return to home</Button>
        </div>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
